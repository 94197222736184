.navigation {
  /* display: flex;
  gap: 10px; */
}

.navigation > ul {
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
