.videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.videos__card {
  opacity: 0.4;
  transition: var(--transition);
  /* aspect-ratio: 1.78; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  width: calc(100%/3 - 40px/3);
}

.videos__card:hover {
  opacity: 1;
}

.videos__card > iframe {
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.videos__card_last {
  background: #333333;
  border-radius: 10px;
  font-size: 18px;
  border-radius: 10px;
  font-size: 18px;
  flex-direction: column;
  gap: 10px;
}

.videos__card_last > svg {
  width: 70px;
  height: 70px;
}

@media (max-width: 960px) {
  .videos__card {
    flex-grow: 1;
  }
}

@media (max-width: 580px) {
  .videos__card {
    min-width: 100%;
  }
}
