.fixtures-wrapper {
  margin: var(--top-gap) 0 var(--main-gap);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  animation: opacity 0.7s;
}

.fixtures {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
}


