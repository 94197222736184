.select {
  width: 160px;
  font-size: 13px;
  border-radius: 7px;
  height: 27px;
  border: none;
  box-sizing: border-box;
  background: #1f1f1f;
  color: inherit;
  box-shadow: 0px 0px 2px #1f1f1f;
  outline: none;
  cursor: pointer;
}
