.main {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--main-gap);
  margin-bottom: var(--main-gap);
  animation: opacity 0.7s;
}

.main__container {
  display: flex;
  justify-content: center;
  /* margin: auto; */
  gap: 30px;
  /* padding-bottom: 20px; */
  flex-wrap: wrap;
}


