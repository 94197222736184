.standings__heading-container {
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 5px;
}

.standings__heading {
  margin: 0;
  font-size: var(--header-font);
  font-weight: 400;
}

.standings__heading-container_inactive {
  display: none;
}

.standings__link {
  color: var(--light-grey);
  transition: var(--transition);
}

.standings__link:hover {
  color: var(--font-color);
}

.standings__link_inactive {
  display: none;
}

.standings_full {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: var(--top-gap) auto var(--main-gap);
  animation: opacity 0.7s;
}

.table {
  padding: var(--padding-table);
  background: var(--second-grey);
  border-radius: var(--radius-table);
  border-spacing: 0;
  min-width: 355px;
  /* margin: 30px auto; */
}

.table__head-row {
  height: 30px;
  position: sticky;
  top: 0;
  background: #272727ed;
  z-index: 4;
}

.table__header-cell {
  min-width: 35px;
  transition: var(--transition);
  font-size: 13px;
  font-weight: 300;
}

.table__head-row > .table__head-cell_wide {
  min-width: 150px;
}

.table__head-cell_inactive {
  display: none;
}

.table__row {
  height: 35px;
  text-align: center;
}

.table__row:nth-child(odd) {
  background: var(--table-grey);
}

.table__row:nth-child(even) {
  background: var(--second-grey);
}

.table__row .table__row_main {
  background: #535353;
}
.table__data_active {
  background-color: #c8102e1a;
}

.table__logo {
  width: 23px;
}

.table__data_non-align {
  text-align: start;
  padding-left: 12px;
  min-width: 120px;
}

.table__data_ucl {
  border-left: 2px solid #4caf50;
}

.table__data_rlg {
  border-left: 2px solid #ff5722;
}

.table__data_el {
  border-left: 2px solid #ffc107;
}

.table__data_inactive {
  display: none;
}

.table-wrapper {
  border-radius: var(--radius-table);
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 900px) {
  .standings_full {
    width: var(--page-width);
    overflow: auto;
    height: 85vh;
    border-radius: var(--radius-table);
  }

  .table-wrapper {
    overflow: auto;
    height: 85vh;
  }

  td:nth-child(1), th:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    background: inherit;
  }

  td:nth-child(2), th:nth-child(2) {
    position: sticky;
    left: 47px;
    z-index: 1;
    background: inherit;
  }

  .standings_full td:nth-child(2) {
    position: sticky;
    left: 37px;
    z-index: 1;
    background: inherit;
  }

  .standings_full td:nth-child(3) {
    position: sticky;
    left: 62px;
    z-index: 1;
    background: inherit;
  }

}

@media (max-width: 580px) {

  .standings_full .table__data_non-align {
    min-width: 100px;
  }

  .standings_full td:last-of-type, .standings_full th:last-of-type {
    position: sticky;
    right: 0;
    z-index: 1;
    background: inherit;
    box-shadow: -2px 0px 5px 0px #404040db;
  }
}

@media (max-width: 370px) {

  .table {
    padding: 7px;
    min-width: 300px;
  }

  .table__row {
    font-size: 0.95em;
  }

  .table__data_non-align {
    min-width: 90px;
  }

}

