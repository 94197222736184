.chart-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: var(--top-gap) 0 var(--main-gap);
  gap: 25px;
  animation: opacity 0.7s;
}

.chart-list__container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
