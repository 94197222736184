:root {
  --page-width: 98%;
  --header-font: 18px;
  --page-background: rgb(31 31 31);
  --second-grey: #272727;
  --font-color: #c1c1c1;
  --light-grey: #828282;
  --table-grey: #353434;
  --radius-table: 10px;
  --padding-table: 15px;
  --main-font: 15px;
  --transition: all 0.3s;
  --main-gap: 70px;
  --top-gap: 25px;
}

body {
  overflow-y: scroll;
}

.page {
  /* min-height: 100vh; */
  background-color: var(--page-background);
  font-family: 'Noto Sans Display', Sans-serif, Arial, Helvetica;;
  font-weight: 300;
  color: var(--font-color);
  font-size: var(--main-font);
}

.page__container {
  margin: auto;
  width: var(--page-width);
  max-width: 1300px;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



