.events {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.events_inactive {
  display: none;
}


.events__container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 5px;
}

.events__heading {
  margin: 0;
  font-size: var(--header-font);
  font-weight: 400;
}

.events__link {
  color: var(--light-grey);
  transition: var(--transition);
}

.events__link:hover {
  color: var(--font-color);
}
