.news {
  display: flex;
  /* width: 200px; */
  height: 180px;
  position: relative;
}

.news h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 200;
  padding: 7px;
  max-height: 50%;
  overflow: hidden;
}

.news__img {
  width: 100%;
  border-radius: 5px;
  object-fit: contain;
  filter: contrast(0.2);
  -webkit-filter: contrast(0.2);
  transition: var(--transition);
  padding-top: 15%;
}

.news a {
  width: 100%;
}

.news__overlay {
  position: absolute;
  background: linear-gradient(180deg, rgb(0 0 0 / 75%) 37%, rgb(43 43 43 / 0%) 100%);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: var(--transition);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news:hover .news__overlay {
  /* height: 80%; */
  color: #fff;
}

.news:hover .news__img {
  filter: none;
}

.news__overlay p {
  padding: 5px;
}


