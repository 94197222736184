.promo {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 35px;
}

.promo__img {
  filter: contrast(0.1);
  -webkit-filter: contrast(0.1);
  opacity: 0.2;
  /* max-height: 450px; */
  border-radius: 15px;
  object-fit: cover;

  width: 100%;
  display: block;
}

.promo__container {
  position: relative;

  width: 27%;
  max-width: 370px;
  max-height: 470px;
  min-width: 250px;
}

.promo/* __container */ > h1 {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 17%;
  width: 20%;
  min-width: 382px;
  backdrop-filter: blur(4px);
  background: #1616166b;
  padding: 3px;
  font-weight: 100;
  z-index: 1;
}

.promo__cards-container {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.promo__card {
  width: 290px;
  box-sizing: border-box;
  background: #272727;
  border-radius: 25px;
  padding: 15px;
  position: relative;
}

.promo__cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo__chart-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -90%);
  text-align: center;
}

.promo__chart-title > h3 {
  margin: 0;
  font-size: 2em;
}

.promo__card_small {
  width: 130px;
  height: 90px;
  border-radius: 15px;
  background: #febf4c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #1f1f1f;
  box-sizing: border-box;
  text-align: center;
}

.promo__card_small > h3 {
  font-size: 22px;
  margin: 9px 0 0;
}

.promo__card_grey {
  background: #c6d0bc;
}

.promo__card_black {
  background: #333333;
  color: #c1c1c1;
}

.promo__small-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.promo__note {
  position: absolute;
  margin: 0;
  /* bottom: -12%; */
  bottom: -25px;
  font-size: 0.9em;
  color: var(--light-grey);
}

@media (max-width: 1130px) {
  .promo > h1 {
    top: -7%;
    left: 28%;
    background: none;
    width: auto;
    font-size: 1.7em;
  }

  .promo__chart-title > h3 {
    font-size: 1.7em;
  }
}

@media (max-width: 780px) {
  .promo {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: var(--main-gap)
  }

  .promo > h1 {
    top: 39%;
    left: 35%;
    width: 315px;
    min-width: auto;
    background: #1616166b;
  }

  .promo__container {
    width: auto;
    max-height: inherit;
  }

  /* .promo__note {
    bottom: -7%;
    top: auto;
  } */
}

@media (max-width: 580px) {
  .promo > h1 {
    top: 39%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.6em;
    width: 260px;
  }

  .promo__container {
    width: 90%;
  }

  .promo__small-container {
    flex-direction: row;
    justify-content: center;
  }

  .promo__card_small {
    width: 29%;
  }

  /* .promo__note {
    bottom: -25px;
  } */
}
