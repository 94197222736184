.chart {
  width: 45%;
  max-width: 500px;
  min-width: 350px;
  padding: 10px;
  box-sizing: border-box;
  background: var(--second-grey);
  border-radius: var(--radius-table);
  flex-grow: 1;
}

.chart__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}

.chart__container > h3 {
  font-weight: 300;
  margin: 0;
}

.chart__info {
  font-size: 13px;
  color: #666666;
  text-align: center;
  margin-top: 10px;
}

.chart__info_inactive {
  visibility: hidden;
}
