.card {
  width: 220px;
  height: 125px;
  background: #272727;
  border-radius: var(--radius-table);
  transition: var(--transition);
  flex-grow: 1;
}

.card:hover {
  cursor: pointer;
  background: #4f4f4f;
  color: white;
}

.card__title {
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0;
}

.card > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 580px) {
  .card {
    max-width: 355px;
  }
}
