.table-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: var(--top-gap) 0 var(--main-gap);
  gap: 25px;
  animation: opacity 0.7s;
}

.players-table {

}

.players-table__header-cell_active {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}

.players-table__header-cell:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}

.players-table__head-row > th {
  min-width: 55px;
}

@media (max-width: 580px) {

  .players-table__head-row > th {
    min-width: 45px;
  }
}

