.fixtures-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  grid-column-gap: 10px;
  grid-row-gap: 8px;
  /* padding: 12px 3px; */
  position: relative;
  width: 100%;
  border-bottom: 1px solid #353434;
  min-height: 85px;
}

.fixtures-card__challenge-container {
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.7;
}

.fixtures-card__container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.fixtures-card__team-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
}

.fixtures-card__team-container_away {
  justify-content: flex-start;
}

.fixtures-card__team {
  overflow: hidden;
  white-space: nowrap;
  width: 97px;
}

.fixtures-card__team_home {
  text-align: end;
}

.fixtures-card__logo {
  width: 40px;
}

.fixtures-card__time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  min-width: 55px;
}

.fixtures-card__time {

}

.fixtures-card__date {
  color: var(--light-grey);
}

.fixtures-card__challenge {
  white-space: nowrap;
  width: 160px;
}

.fixtures-card__challenge-logo {
  width: 35px;
  opacity: 0.6;
}

.fixtures-card__result-сontainer {
  display: flex;
  justify-content: space-around;
  font-size: 17px;
  background: var(--table-grey);
  padding: 4px;
  border-radius: 5px;
  width: 55px;
  box-sizing: border-box;
  align-items: center;
}

._win {
  border-bottom: 3px solid #2fa523;
}

._lose {
  border-bottom: 3px solid #d62121;
}

._draw {
  border-bottom: 3px solid #d3b814;
}

._inactive {
  display: none;
}

.fixtures-card__date-container {
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.7;
}

.fixtures-card__calendar-logo {
  width: 30px;
}

.fixtures-card__finish-date {
  font-size: 13px;
  color: var(--light-grey);
  text-align: end;
  margin-right: 5px;
}

@media (max-width: 780px) {
  .fixtures-card__challenge {
    display: none;
  }
}

@media (max-width: 580px) {

  .fixtures-card {
    margin-top: 3%;
    align-items: flex-start;
    min-height: 65px;
  }

  .fixtures-card__container {
    gap: 10px;
  }

  .fixtures-card__challenge {
    display: none;
  }

  .fixtures-card__calendar-logo {
    display: none;
  }

  .fixtures-card__challenge-container {
    right: 5%;
    bottom: 5px;
    position: absolute;
  }

  .fixtures-card__date-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
  }

  .fixtures-card__finish-date {
    margin-right: 0;
  }

  .fixtures-card__challenge-logo {
    width: 1.7em;
  }

  .fixtures-card__logo {
    width: 35px;
  }

  .fixtures-card__team {
    width: 80px;
    font-size: 0.95em;
  }

  .fixtures-card__result-сontainer {
    width: 50px;
  }
}
