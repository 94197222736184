@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-200%);
    /* -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%); */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    /* -webkit-transform: translateX(0);
    -moz-transform: translateX(0); */
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(200%);
    /* -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%); */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    /* -webkit-transform: translateX(0);
    -moz-transform: translateX(0); */
  }
}

.trophies {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-around;
  overflow: hidden;
  gap: 5%;
  flex-wrap: wrap;
}

.trophies__card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* transition: all 0.5s; */
  opacity: 0;
}

.trophies__img {
  height: 130px;
  opacity: 0.25;
}

.trophies__img_black {
  filter: contrast(0.4);
  -webkit-filter: contrast(0.4);
}

.trophies__card > p {
  max-width: 100px;
  text-align: center;
}

.trophies__img-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.trophies__img-container > h3 {
  margin: 0;
  font-size: 18px;
  position: absolute;
  font-weight: 100;
  bottom: 40px;
}

.trophies__img_small {
  width: 120px;
  height: auto;
}

/* .test {
  animation: slide-left 0.5s ease-in 0.1s forwards;
} */

/* animation: slide-left 0.5s ease-in 0.8s forwards;
  -webkit-animation: slide-left 0.5s ease-in 0.8s forwards; */

  .trophies__card_active:first-of-type {
    animation: slide-left 0.5s ease-in 0.3s forwards;
    /* -webkit-animation: slide-left 0.5s ease-in 0.8s forwards; */
  }

  .trophies__card_active:nth-of-type(2) {
    animation: slide-left 0.5s ease-in 0.2s forwards;
    /* -webkit-animation: slide-left 0.5s ease-in 0.9s forwards; */
  }

  .trophies__card_active:nth-of-type(3) {
    animation: slide-left 0.5s ease-in 0.1s forwards;
    /*-webkit-animation: slide-left 0.5s ease-in 1s forwards; */
  }

  .trophies__card_active:nth-of-type(4) {
    animation: slide-left 0.5s ease-in forwards;
   /*-webkit-animation: slide-left 0.5s ease-in 1.1s forwards; */
  }

  .trophies__card_active:nth-of-type(5) {
    animation: slide-right 0.5s ease-in 0.1s forwards;
   /*-webkit-animation: slide-left 0.5s ease-in 1.1s forwards; */
  }

  .trophies__card_active:nth-of-type(6) {
    animation: slide-right 0.5s ease-in 0.2s forwards;
   /*-webkit-animation: slide-left 0.5s ease-in 1.1s forwards; */
  }

  .trophies__card_active:nth-of-type(7) {
    animation: slide-right 0.5s ease-in 0.3s forwards;
   /*-webkit-animation: slide-left 0.5s ease-in 1.1s forwards; */
  }
