.event-card {
  display: grid;
  grid-template-columns: auto 50px auto;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
  grid-row-gap: 8px;
  background: var(--second-grey);
  border-radius: var(--radius-table);
  padding: 12px 3px;
}

.event-card__team-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
}

.event-card__team-container_away {
  justify-content: flex-start;
}

.event-card__team {
  overflow: hidden;
  white-space: nowrap;
  width: 97px;
}

.event-card__team_home {
  text-align: end;
}

.event-card__logo {
  width: 35px;
}

.event-card__date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}

.event-card__time {

}

.event-card__date {
  color: var(--light-grey);
}

.event-card__challenge {
  display: grid;
  grid-column: 2;
  align-items: baseline;
  justify-content: center;
  font-size: 13px;
  color: var(--light-grey);
  white-space: nowrap;
}

.event-card__result-сontainer {
  display: flex;
  justify-content: space-around;
  font-size: 17px;
  background: var(--table-grey);
  padding: 4px;
  border-radius: 5px;
}

._win {
  border-bottom: 3px solid #2fa523;
}

._lose {
  border-bottom: 3px solid #d62121;
}

._draw {
  border-bottom: 3px solid #d3b814;
}

._inactive {
  display: none;
}

.event-card__finish-date {
  font-size: 13px;
  color: var(--light-grey);
  text-align: end;
  margin-right: 5px;
}

@media (max-width: 370px) {
  .event-card {
    grid-column-gap: 6px;
    grid-template-columns: auto 45px auto;
  }
  .event-card__team {
    width: 76px;
    font-size: 0.95em;
  }
}
